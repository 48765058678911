@brand-font: 'BioRhyme', serif;
@standard-font: 'Space Mono', monospace;
@palette-dark: #0F0F0F;
@palette-light: #eeeeee;
@palette-primary: #ff2a1d;
@side-padding: 5vw;
@header-animation-speed: 0.2s; 
@header-easing: linear;

@screen-xxs-max: 575px;
@screen-xs-min: @screen-xxs-max + 1;
@screen-xs-max: 768px;
@screen-sm-min: @screen-xs-max + 1;
@screen-sm-max: 991px;
@screen-md-min: @screen-sm-max + 1;
@screen-md-max: 1199px;
@screen-lg-min: @screen-md-max + 1;

.active-link() {
    background: linear-gradient(to right, @palette-light 20%, @palette-primary 40%, @palette-primary 60%, @palette-light 80%);
    background-size: 200% auto;                
    color: #ffffff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;       
    animation: shine 4s linear infinite;
    @media (prefers-reduced-motion: reduce) { 
        animation: none;
    }
}

.gpu() {
    box-shadow: 0 0 0 #000;
    transform: translate3d(0,0,0);
}

* {
  box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: @standard-font;
    background: @palette-dark;
    color: @palette-light;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    &.no-hero {
        padding-top: 175px;
    }
}

header {
    .gpu();
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px @side-padding;
    color: @palette-dark;
    transition: background-color @header-animation-speed @header-easing, border-color @header-animation-speed @header-easing;
    @media (prefers-reduced-motion: reduce) { 
        transition: none;
    }
    border-bottom: 4px solid transparent;
    text-align: center;
    @media (min-width: @screen-md-min) {
        text-align: left;
    }

    &.isStuck {
        background-color: fade(@palette-dark, 95%);
        color: @palette-light;
        border-bottom-color: fade(@palette-primary, 50%);

        .logo {
            margin: 10px 0 0 0;
            font-size: 2rem;
            line-height: 0.3;
            @media (min-width: @screen-md-min) {
                font-size: 3rem;
            }
            span {
                font-size: 3.5rem;
                transform: translate(99%, -100%);
                @media (min-width: @screen-md-min) {
                    transform: translate(92%, -100%);
                    font-size: 4rem;
                }
            }
        }

        .open-menu-button {
            color: @palette-light;
        }
    }

    .no-hero & {
        color: @palette-light;

        .open-menu-button {
            color: @palette-light;
        }
    }

    &.open {
        nav {
            opacity: 1;
            pointer-events: all;
            color: @palette-light;

            ul {
                @media (max-width: @screen-sm-max) {
                    display: block;
                }
            }
        }

        .close-menu-button {
            @media (max-width: @screen-sm-max) {
                display: block;
            }
        }
    }

    nav {
        z-index: 1;
        @media (max-width: @screen-sm-max) {
            opacity: 0;
            transition: opacity @header-animation-speed @header-easing;
            position: fixed;
            pointer-events: none;
            top: 0;
            right: 0;
            height: 100vh;
            left: 0;
            bottom: 0;
            background: fade(@palette-dark, 85%);
        }

        ul {
            display: none;
            @media (min-width: @screen-md-min) {
                display: flex;
            }
            justify-content: flex-end;
            list-style: none;
            text-transform: uppercase;
            font-family: @standard-font;
            font-weight: bold;
            font-size: 4.0rem;
            @media (min-width: @screen-md-min) {
                font-size: 2.0rem;
            }
            margin: 0;

            li {
                @media (min-width: @screen-md-min) {
                    margin-right: 4rem;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .active {
                a {
                    .active-link();
                }
            }

            a {
                color: currentColor;
                text-decoration: none;

                &:hover {
                    .active-link();
                }
            }
        }
    }
}

.open-menu-button {
    display: block;
    @media (min-width: @screen-md-min) {
        display: none;
    }
    position: absolute;
    top: 10px;
    right: @side-padding;
    z-index: 1;
    mix-blend-mode: difference;
    border: none;
    outline: none;
    text-transform: uppercase;
    background: transparent;
    transition: color @header-animation-speed @header-easing;
    @media (prefers-reduced-motion: reduce) { 
        transition: none;
    }
    color: @palette-dark;
    cursor: pointer;
}

.close-menu-button {
    display: none;
    position: absolute;
    top: 10px;
    right: 5vw;
}

.container {
    padding: 40px @side-padding;
    @media (min-width: @screen-sm-min) {
        padding: 40px 15vw;
    }
}

.hero {
    background: @palette-primary;
    background: url(../assets/background.png);
    background-size: cover;
    background-position: center bottom;
    height: 65vh;
    min-height: 65vh;
    @media (min-width: @screen-sm-min) {
        height: 85vh;
        min-height: 85vh;
    }
    position: relative;
}

.logo {
    .gpu();
    margin: 0 auto;
    transition: font-size @header-animation-speed @header-easing, color @header-animation-speed @header-easing;
    @media (prefers-reduced-motion: reduce) { 
        transition: none;
    }
    text-transform: uppercase;
    color: currentColor;
    display: inline-block;
    text-align: center;
    font-size: 3rem;
    line-height: 4.7rem;
    @media (min-width: @screen-md-min) {
        margin: 0;
        text-align: right;
        font-size: 7.2rem;
        line-height: 7rem;
    }
    font-family: @brand-font;
    font-weight: bold;
    
    span {
        display: block;
        position: relative;
        transition: transform @header-animation-speed @header-easing;
        @media (prefers-reduced-motion: reduce) { 
            transition: none;
        }
        transform: translate(0, 0);
        top: 100%;
        font-size: 5.8rem;
        @media (min-width: @screen-md-min) {
            font-size: 9.6rem;
        }
        font-family: @standard-font;
        font-weight: bolder;
    }
}

.horse {
    background: url(../assets/horse.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -4px;
    right: 15vw;
    transform: scaleX(-1);
}

h1 {
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 4.8rem;
    line-height: 1.2;
    font-family: @brand-font;
}

h1 + p {
    font-size: 2.4rem;
}

a {
    color: @palette-primary;
    transition: opacity 0.2s ease-in;

    &:hover {
        opacity: 0.6;
    }
}

footer {
    margin: 30px 0 40px 0;
    @media (min-width: @screen-xs-min) {
        margin: 35px 0 50px 0;
    }
}

.social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px !important;

    li {
        margin: 0 5px;
    }
}

.wiredsussex-logo {
    display: inline-block;
    background: white;
    border-radius: 3px;
    padding: 8px;
    margin-top: 20px;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

.skip-link {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}